import React, { Component } from 'react';
import loadingGif from '../../ripple.gif';
import ReactDOMServer from 'react-dom/server';
import { db } from '../../firebase';
import { PDFExport } from '@progress/kendo-react-pdf';
import Breadcrumbs from '../../components/breadcrumbs';
import { Link } from 'react-router-dom';
import { average, difference } from '../../helpers';
import RadarChart from '../../components/charts/radarchart';
import SiteHeader from '../../components/site-header';
import LoadingState from '../../components/global/loading-state';
import withAuthorisation from '../../components/withauthorisation';
import ReportPageTemplate from './reportpagetemplate.jsx';
import { object } from 'webidl-conversions';
import DetailedFeedbackSection from '../../components/reports/detailedFeedbackSection';

const authCondition = (authUser) => !!authUser;

export class Group360Report extends Component {
  constructor(props) {
    super(props);
    this.exportPDF = this.exportPDF.bind(this);
    this.convertSVGToImage = this.convertSVGToImage.bind(this);
    this.updatePDFExportPreRenderStatus = this.updatePDFExportPreRenderStatus.bind(this)

    this.state = {
      loadingState: { loading: true, reviewee: 0 },
      error: false,
      captions: {},
      userData: {},
      userId: null,
      groupId: null,
      current360Id: null,
      current360Relationships: this.props.current360Relationships,
      allRevieweeAnswers: [],
      allReviewerAnswers: [],
      sectionsLegacyMode: false,
      pdfRequested: false,
      pdfRenderCompleted: false,
    };
  }

  exportPDF = () => {
    // this.report.save();
    if (this.state.pdfRenderCompleted) {
      this.report.save();
    } else {
      // triggers re-render process to split each question into pages that account for overflow
      this.setState({pdfRequested: true})
    }
  };

  convertSVGToImage = (htmlString) => {
    try {
      // Take an SVG html string, convert it to static markup
      let staticRadarChart = ReactDOMServer.renderToStaticMarkup(htmlString);

      // Convert SVG string into a data URI
      const svgDataUri = `data:image/svg+xml;base64,${btoa(unescape(encodeURIComponent(staticRadarChart)))}`;

      return svgDataUri;
    } catch (err) {
      // console.log(err);
      return null;
    }
  };

  componentDidMount() {
    const {
      match: { params },
    } = this.props;
    const theState = this.state;

    this.setState({
      groupId: params.groupId,
      current360Id: params.id,
    });

    db.getReportInformation(this.props.match.params.accountId, this.props.match.params.id, this.props.match.params.groupId, 'group-report').then((doc) => {
      if (doc.exists && doc.data().updateByReview === false) {
        let reportData = doc.data().reportData;
        // accounts for legacy reports, where relationshipKeys were not saved to report data in firebase
        if (!doc.data().reportData.relationshipKeys) {
          reportData['relationshipKeys'] = Object.keys(reportData.reviewerSecCount);
        }
        let questionsList = reportData.questionDetails;
        let questionsLegacy = Object.keys(questionsList)
          .map((qID) => questionsList[qID].sortOrder)
          .includes(undefined);
        this.setState({
          ...reportData,
          loadingState: { loading: false },
          questionsLegacy,
        });
      } else {
        db.doGetCurrentGroup(this.props.match.params.accountId, params.id, params.groupId).onSnapshot((snapshot) => {
          if (snapshot.data()) {
            this.setState({
              currentGroup: snapshot.data(),
            });

            const questionnaireID = this.props.current360.questionnaireID;
            const current360Title = this.props.current360.title;
            const current360Relationships = this.state.current360Relationships;
            db.doGetSectionsAndQuestionDetailsList(params.accountId, questionnaireID).then((sections) => {
              if (
                Object.values(sections)
                  .map((section) => section.sortOrder)
                  .includes(undefined)
              ) {
                this.setState({
                  sectionsLegacyMode: true,
                });
              }

              let questionnaireSections = sections;
              let captions = {};
              let descriptions = {};
              let questionDetails = {};
              let sectionDetails = questionnaireSections;
              let questionnaireShape = {};
              let relationshipKeys = [];
              let dfSectionRelationshipKeys = [];

              Object.keys(questionnaireSections).map((key, index) => {
                let sectionID = key;
                questionnaireShape[sectionID] = [];

                if (questionnaireSections[sectionID].questions) {
                  // Set captions + description!
                  captions[[sectionID]] = questionnaireSections[sectionID].title;
                  descriptions[[sectionID]] = questionnaireSections[sectionID].description;

                  Object.keys(questionnaireSections[sectionID].questions).map((key, index) => {
                    questionDetails[[key]] = questionnaireSections[sectionID].questions[key];
                    if (questionnaireSections[sectionID].questions[key].answerType === 'MultiChoice') {
                      questionnaireShape[sectionID].push(key);
                    }
                  });
                }
              });

              db.doGetCurrentGroupUsers(this.props.match.params.accountId, params.id, params.groupId)
                .get()
                .then((res) => {
                  const revieweeCount = res.docs.length;

                  res.docs.forEach((doc, n) => {
                    const currentRevieweeInt = n + 1;
                    const reviewee = doc;

                    this.setState({
                      loadingState: {
                        loading: true,
                        reviewee: currentRevieweeInt,
                      },
                    });
                    db.doGetSelfReviewCompletionPercentage(params.accountId, reviewee.id, params.id, params.groupId, questionnaireID, 'self-review').then(
                      (res) => {
                        let userIndividualAnswers = [];
                        let userIndividualResponses = [];
                        let userAnswers = {};
                        let userResponses = {};
                        let userCommentsBySection = {};

                        let compCheck = res;
                        if (compCheck === 100) {
                          db.doGetUserAnswersBySections(
                            params.accountId,
                            reviewee.id,
                            params.id,
                            params.groupId,
                            questionnaireID,
                            'self-review',
                            questionnaireSections
                          ).then(async (data) => {
                            let questionnaireSections = data;
                            Object.keys(questionnaireSections).map((key, index) => {
                              let sectionKey = key;
                              let answers = {};
                              let comments = [];
                              let responses = [0, 0, 0, 0, 0, 0];
                              let answerKey = 0;

                              Object.keys(questionnaireSections[sectionKey].answers).map((key, index) => {
                                let answerVal = questionnaireSections[sectionKey].answers[key];
                                // If answer is a number value (IE: not a text field) then set, otherwise set to `0`

                                answerVal = isNaN(Number(answerVal)) ? -1 : parseInt(answerVal);

                                if (answerVal == -1) {
                                  let userComment = questionnaireSections[sectionKey].answers[key];

                                  if (!userCommentsBySection[[sectionKey]]) {
                                    userCommentsBySection[[sectionKey]] = {};
                                  }
                                  if (!userCommentsBySection[[sectionKey]][[key]]) {
                                    userCommentsBySection[[sectionKey]][[key]] = {};
                                    userCommentsBySection[[sectionKey]][[key]]['title'] = sectionDetails[sectionKey].questions[key].questionTitle;
                                  }

                                  userCommentsBySection[[sectionKey]][[key]]['comment'] = userComment;
                                } else {
                                  responses[answerVal] = responses[answerVal] + 1;
                                  // user `answerKey` instead of `key` to ensure correct average

                                  if (answerVal.toString() !== 'NaN' && answerVal !== 0) {
                                    answers[key] = answerVal;
                                    userIndividualAnswers[[key]] = answerVal;
                                  }

                                  answerKey++;
                                }
                              });
                              userResponses[[sectionKey]] = responses;
                              userAnswers[sectionKey] = answers;
                            });

                            theState.allRevieweeAnswers.push({
                              [reviewee.id]: {
                                userIndividualAnswers: userIndividualAnswers,
                                userAnswers: userAnswers,
                                userResponses: userResponses,
                                userCommentsBySection: userCommentsBySection,
                              },
                            });

                            db.doGetCompletedReviewers(params.accountId, params.id, params.groupId, reviewee.id).then((snapshot) => {
                              if (!snapshot.empty) {
                                let reviewers = {};
                                snapshot.docs.map((doc, i) => {
                                  reviewers[doc.id] = doc.data();
                                  const {relationship} = doc.data();
                                  dfSectionRelationshipKeys.push(relationship);
                                  if (!relationshipKeys.includes(relationship)) {
                                    relationshipKeys.push(relationship)
                                  }
                                });
                                dfSectionRelationshipKeys.sort();
                                relationshipKeys.sort();

                                let numberOfReviewers = Object.keys(reviewers).length;
                                let completedReviewerAnswers = {};
                                let completedReviewerResponses = {};

                                let reviewerAnswersBySection = {};

                                Object.keys(current360Relationships).map((key) => {
                                  completedReviewerAnswers[key] = {};
                                  completedReviewerResponses[key] = {};
                                });

                                Object.keys(reviewers).map(async (key, index) => {
                                  let reviewerCountBySection = {};

                                  let reviewerKey = key;

                                  let reviewerAnswersCount;
                                  let loopNum = index + 1;
                                  let reviewerRelationship = reviewers[reviewerKey]['relationship'];
                                  reviewerAnswersBySection[reviewerKey] = {};

                                  const getReviews = async (reviewerKey) => {
                                    let fetchAnswers = await db.doGetReviewerAnswers(params.accountId, reviewerKey, questionnaireSections);
                                    let Answers = await fetchAnswers;

                                    return Answers;
                                  };

                                  const reviewerAnswersSections = await getReviews(reviewerKey);

                                  Object.keys(reviewerAnswersSections).map((key, index) => {
                                    const sectionKey = key;
                                    let answers = [];
                                    let responses = [0, 0, 0, 0, 0, 0];
                                    if (!reviewerAnswersBySection[reviewerKey][sectionKey]) {
                                      reviewerAnswersBySection[reviewerKey][sectionKey] = {};
                                      reviewerCountBySection[sectionKey] = [];
                                    }
                                    Object.keys(reviewerAnswersSections[sectionKey].answers).map((key, index) => {
                                      const commentCheck = isNaN(parseInt(reviewerAnswersSections[sectionKey].answers[key]));
                                      if (!commentCheck) {
                                        const answer = parseInt(reviewerAnswersSections[sectionKey].answers[key]);
                                        //increment the answers counter
                                        responses[answer]++;

                                        //remove the 0s from the array so it does not effect the averaging
                                        //0s are only needed to be included in the overall count
                                        if (answer !== 0) {
                                          reviewerAnswersBySection[reviewerKey][sectionKey][key] = answer;
                                        }
                                      }
                                      reviewerCountBySection[sectionKey] = responses;
                                    });
                                  });
                                  theState.allReviewerAnswers.push({
                                    [reviewerKey]: {
                                      relationship: reviewerRelationship,
                                      userAnswers: reviewerAnswersBySection[reviewerKey],
                                      reviewerCountBySection: reviewerCountBySection,
                                    },
                                  });

                                  if (Object.keys(reviewers).length === loopNum) {
                                    //pass anything not immediately needed to the state
                                    this.setState({
                                      captions: captions,
                                      descriptions: descriptions,
                                      sectionDetails: sectionDetails,
                                      dfSectionRelationshipKeys,
                                      relationshipKeys,
                                    });
                                    this.calculateAverages(theState.allRevieweeAnswers, theState.allReviewerAnswers, questionnaireShape);
                                  }
                                });
                              }
                            });
                          });
                        } else if (currentRevieweeInt === revieweeCount) {
                          //pass anything not immediately needed to the state
                          this.setState({
                            captions: captions,
                            descriptions: descriptions,
                            sectionDetails: sectionDetails,
                            dfSectionRelationshipKeys,
                            relationshipKeys,
                          });
                          this.calculateAverages(theState.allRevieweeAnswers, theState.allReviewerAnswers, questionnaireShape);
                        }
                      }
                    );
                  });
                });
            });
          }
        });
      }
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.pdfRenderCompleted !== this.state.pdfRenderCompleted) {
      // once the re-render for PDF has been completed, the PDF will be generated
      this.report.save();
    }
  }

  updatePDFExportPreRenderStatus() {
    this.setState({pdfRenderCompleted: true})
  }

  calculateAverages(revieweeScores, reviewerScores, questionnaireShape) {
    let reviewersByType = {};
    Object.keys(this.props.current360Relationships).map((rel) => {
      reviewersByType[rel] = [];
      reviewerScores.map((score) => {
        const scoreID = Object.keys(score)[0];
        const scoreObj = score[scoreID];
        if (rel === scoreObj.relationship) {
          reviewersByType[scoreObj.relationship].push(score);
        }
      });
    });

    let revSec = {};
    let userCountArr = {};
    revieweeScores.map((score, n) => {
      const scoreID = Object.keys(score)[0];
      const scoreObj = score[scoreID];
      Object.keys(scoreObj.userResponses).map((secKey, i) => {
        if (!revSec[secKey]) {
          revSec[secKey] = [0, 0, 0, 0, 0, 0];
          userCountArr[secKey] = {};
        }
        scoreObj.userResponses[secKey].map((count, p) => {
          revSec[secKey][p] += count;
        });

        Object.keys(this.state.sectionDetails[secKey].questions).map((ansKey, n) => {
          if (this.state.sectionDetails[secKey].questions[ansKey].answerType === 'MultiChoice') {
            if (!userCountArr[secKey][ansKey]) {
              userCountArr[secKey][ansKey] = [0, 0, 0, 0, 0, 0];
            }
            let answerVal = scoreObj.userAnswers[secKey][ansKey];
            if (answerVal === undefined) {
              answerVal = 0;
            }
            userCountArr[secKey][ansKey][answerVal]++;
          }
        });
      });
    });

    let revSecCount = {};
    let revSecCountArr = {};
    let secCountNoComents = {};
    reviewerScores.map((score, n) => {
      const scoreID = Object.keys(score)[0];
      const scoreObj = score[scoreID];
      if (!revSecCount[scoreObj.relationship]) {
        revSecCount[scoreObj.relationship] = {};
        revSecCountArr[scoreObj.relationship] = {};
      }
      Object.keys(scoreObj.reviewerCountBySection).map((secKey, i) => {
        if (!revSecCount[scoreObj.relationship][secKey]) {
          revSecCount[scoreObj.relationship][secKey] = [0, 0, 0, 0, 0, 0];
        }
        scoreObj.reviewerCountBySection[secKey].map((count, p) => {
          revSecCount[scoreObj.relationship][secKey][p] += count;
        });
      });

      Object.keys(this.state.sectionDetails).map((secKey, i) => {
        if (!revSecCountArr[scoreObj.relationship][secKey]) {
          revSecCountArr[scoreObj.relationship][secKey] = {};
          secCountNoComents[secKey] = 0;
        }
        Object.keys(this.state.sectionDetails[secKey].questions).map((ansKey, n) => {
          if (this.state.sectionDetails[secKey].questions[ansKey].answerType === 'MultiChoice') {
            if (!revSecCountArr[scoreObj.relationship][secKey][ansKey]) {
              revSecCountArr[scoreObj.relationship][secKey][ansKey] = [0, 0, 0, 0, 0, 0];
            }
            let answerVal = scoreObj.userAnswers[secKey][ansKey];
            if (scoreObj.relationship == 'DR') {
              // // console.log(scoreObj.userAnswers[secKey]);
            }
            if (answerVal === undefined) {
              answerVal = 0;
            }
            secCountNoComents[secKey]++;
            revSecCountArr[scoreObj.relationship][secKey][ansKey][answerVal]++;
          }
        });
      });
    });
    // // console.log(revSecCountArr);

    const averageAnsFunc = (scoreArr, questionnaireShape) => {
      let answersBySection = {};
      let averagesBySection = {};
      let averagesBySecDiv5 = {};
      let sectionAnswersByType = {
        type: '',
        answersBySection: {},
      };

      //loop over the reviewees/reviewers
      scoreArr.map((score, n) => {
        //this just getst the reviewee ID
        const scoreID = Object.keys(score)[0];
        const scoreObj = score[scoreID];

        //sets the type for easier filtering later on
        if (scoreObj.relationship) {
          sectionAnswersByType.type = scoreObj.relationship;
        } else {
          sectionAnswersByType.type = 'self-reviews';
        }

        //this is for building one big array of answers for easy averaging
        Object.keys(scoreObj.userAnswers).map((secID) => {
          if (!answersBySection[secID]) {
            answersBySection[secID] = [];
            averagesBySection[secID] = 0;
            averagesBySecDiv5[secID] = 0;
          }
          Object.keys(scoreObj.userAnswers[secID]).map((ansKey) => {
            answersBySection[secID].push(scoreObj.userAnswers[secID][ansKey]);
          });
        });

        //this is for getting and average of all self reviews/reviewer types for each question
        Object.keys(questionnaireShape).map((sectionID, n) => {
          if (!sectionAnswersByType.answersBySection[sectionID]) {
            sectionAnswersByType.answersBySection[sectionID] = {};
          }
          //loop over each question
          questionnaireShape[sectionID].map((questionID, i) => {
            if (!sectionAnswersByType.answersBySection[sectionID][questionID]) {
              sectionAnswersByType.answersBySection[sectionID][questionID] = [];
            }
            let answer = scoreObj.userAnswers[sectionID][questionID];
            //dont push a 0 to the array as this is used in calculating averages
            if (answer !== undefined) {
              sectionAnswersByType.answersBySection[sectionID][questionID].push(answer);
            }
          });
        });
      });

      //this gets the average by section
      Object.keys(answersBySection).map((secID) => {
        averagesBySection[secID] = average(answersBySection[secID]);
        averagesBySecDiv5[secID] = average(answersBySection[secID]) / 5;
      });

      return {
        answersBySection: answersBySection,
        averagesBySection: averagesBySection,
        sectionAnswersByType: sectionAnswersByType,
        averagesBySecDiv5: averagesBySecDiv5,
      };
    };

    //get the question answer counts by reviewer e.g. [0,1,1,18,12,1]
    let calcsByType = {};
    let reviewCountBySection = {};
    let reviewCountByAnswer = {};
    Object.keys(reviewersByType).map((type, n) => {
      reviewCountBySection[type] = {};
      reviewCountByAnswer[type] = {};
      if (reviewersByType[type].length > 0) {
        calcsByType[type] = averageAnsFunc(reviewersByType[type], questionnaireShape);
      }
      reviewersByType[type].map((reviewer) => {
        const scoreID = Object.keys(reviewer)[0];
        const scoreObj = reviewer[scoreID];
        Object.keys(scoreObj.reviewerCountBySection).map((secKey) => {
          if (!reviewCountBySection[type][secKey]) {
            reviewCountBySection[type][secKey] = [0, 0, 0, 0, 0, 0];
            reviewCountByAnswer[type][secKey] = {};
          }
          scoreObj.reviewerCountBySection[secKey].map((answer, n) => {
            reviewCountBySection[type][secKey][n] = reviewCountBySection[type][secKey][n] + answer;
          });
          questionnaireShape[secKey].map((QID, n) => {
            if (!reviewCountByAnswer[type][secKey][QID]) {
              reviewCountByAnswer[type][secKey][QID] = [0, 0, 0, 0, 0, 0];
            }
            //if it is undefined then it is a 0 score that has been removed for average calcs
            if (scoreObj.userAnswers[secKey][QID] === undefined) {
              reviewCountByAnswer[type][secKey][QID][0]++;
            } else {
              reviewCountByAnswer[type][secKey][QID][scoreObj.userAnswers[secKey][QID]]++;
            }
          });
        });
      });
    });

    //get an object of all reviewee answers averaged out (for doing the top & lowest 5)
    //returns an object of all the scores for a question averaged (all reviewees answers averaged, all reviewers averaged)
    const allAnswersAverageFunc = (scores) => {
      let answersObj = {};
      let answersArrObj = {};
      const revieweeMap = averageAnsFunc(scores, questionnaireShape).sectionAnswersByType.answersBySection;
      Object.keys(revieweeMap).map((sectionID) => {
        Object.keys(revieweeMap[sectionID]).map((questionID) => {
          answersObj[questionID] = average(revieweeMap[sectionID][questionID]);
          answersArrObj[questionID] = revieweeMap[sectionID][questionID];
        });
      });
      return [answersObj, answersArrObj];
    };

    //average out each section by reviewer type
    //this is for the radar graph
    let reviewerTypeAvBySec = {};
    reviewerScores.map((reviewer, i) => {
      const scoreID = Object.keys(reviewer)[0];
      const scoreObj = reviewer[scoreID];
      if (!reviewerTypeAvBySec[scoreObj.relationship]) {
        //build the object
        reviewerTypeAvBySec[scoreObj.relationship] = {};
        reviewerTypeAvBySec[scoreObj.relationship].all = {};
        reviewerTypeAvBySec[scoreObj.relationship].averages = {};
      }
      Object.keys(scoreObj.userAnswers).map((sectionKey, n) => {
        if (!reviewerTypeAvBySec[scoreObj.relationship].all[sectionKey]) {
          //build the object more
          reviewerTypeAvBySec[scoreObj.relationship].all[sectionKey] = [];
          reviewerTypeAvBySec[scoreObj.relationship].averages[sectionKey] = 0;
        }
        Object.keys(scoreObj.userAnswers[sectionKey]).map((answerKey, m) => {
          reviewerTypeAvBySec[scoreObj.relationship].all[sectionKey].push(scoreObj.userAnswers[sectionKey][answerKey]);
        });
      });
    });

    Object.keys(reviewerTypeAvBySec).map((type, n) => {
      Object.keys(reviewerTypeAvBySec[type].all).map((sectionKey, i) => {
        reviewerTypeAvBySec[type].averages[sectionKey] = average(reviewerTypeAvBySec[type].all[sectionKey]);
      });
    });

    const allRevAnsAver = allAnswersAverageFunc(reviewerScores)[0];
    const allUserAnsAver = allAnswersAverageFunc(revieweeScores)[0];
    const allRevAnsCount = allAnswersAverageFunc(reviewerScores)[1];
    const allUserAnsCount = allAnswersAverageFunc(revieweeScores)[1];

    // let userCountArr = {};
    // Object.keys(allUserAnsCount).map((QID, n) => {
    //   userCountArr[QID] = [0, 0, 0, 0, 0, 0];
    //   allUserAnsCount[QID].map((ans, i) => {
    //     userCountArr[QID][ans]++;
    //   });
    // });
    const highestRated = Object.keys(allAnswersAverageFunc(reviewerScores)[0]).sort((a, b) => {
      return allRevAnsAver[b] - allRevAnsAver[a];
    });

    let weightedArr = [];
    highestRated.map((val, i) => {
      if (i > 0) {
        let current = allRevAnsAver[highestRated[i]];
        let prev = allRevAnsAver[highestRated[i - 1]];
        let currLen = allAnswersAverageFunc(reviewerScores)[1][highestRated[i]];
        let prevLen = (x, y) => allAnswersAverageFunc(reviewerScores)[1][highestRated[x - y]];
        if (current === prev) {
          if (currLen.length > prevLen(i, 1).length) {
            for (let n = 2; n < weightedArr.length; n++) {
              if (!currLen.length > prevLen(i, n).length) {
                weightedArr.splice(weightedArr.length - n, 0, val);
                n = weightedArr.length;
              }
            }
          } else if (currLen.length === prevLen(i, 1).length) {
            const currentArr = currLen.sort(function(a, b) {
              return b - a;
            });
            const previousArr = prevLen(i, 1).sort(function(a, b) {
              return b - a;
            });

            if (currentArr[currentArr.length - 1] > previousArr[previousArr.length - 1]) {
              for (let n = 1; n < weightedArr.length; n++) {
                if (currentArr[currentArr.length - 1] > previousArr[previousArr.length - n]) {
                  weightedArr.splice(weightedArr.length - n, 0, val);
                  n = weightedArr.length;
                }
              }
            } else {
              weightedArr.push(val);
            }
          } else {
            weightedArr.push(val);
          }
        } else {
          weightedArr.push(val);
        }
      } else {
        weightedArr.push(val);
      }
    });

    const calculateDifferences = {};

    Object.keys(allUserAnsAver).map((answerID, n) => {
      calculateDifferences[answerID] = allUserAnsAver[answerID] - allRevAnsAver[answerID];
    });

    const questionDetails = {};
    Object.keys(this.state.sectionDetails).map((secID) => {
      Object.keys(this.state.sectionDetails[secID].questions).map((QID) => {
        questionDetails[QID] = this.state.sectionDetails[secID].questions[QID];
      });
    });

    const theState = this.state;

    db.saveReportInformation(this.props.match.params.accountId, this.state.current360Id, this.props.match.params.groupId, 'group-report', {
      allRevieweeAnswers: revieweeScores,
      allReviewerAnswers: reviewerScores,
      completedIndividualQuestionsDifferences: calculateDifferences,
      reviewerCalcs: calcsByType,
      revieweeCalcs: averageAnsFunc(revieweeScores, questionnaireShape),
      questionnaireShape: questionnaireShape,
      questionDetails: questionDetails,
      revieweeAnswersObj: allUserAnsAver,
      reviewerAnswersObj: allRevAnsAver,
      reviewerCountArr: revSecCountArr,
      revieweeAnswerCount: allUserAnsCount,
      revieweeAnswerCountArr: userCountArr,
      reviewerAnswerCount: allRevAnsCount,
      radarGraphData: reviewerTypeAvBySec,
      reviewerSecCount: revSecCount,
      secLengthNoFreeText: secCountNoComents,
      userCount: revSec,
      weightedArr: weightedArr,
      ...theState,
    }).then(() => {
      this.setState({
        loadingState: { loading: false },
        allRevieweeAnswers: revieweeScores,
        allReviewerAnswers: reviewerScores,
        completedIndividualQuestionsDifferences: calculateDifferences,
        reviewerCalcs: calcsByType,
        revieweeCalcs: averageAnsFunc(revieweeScores, questionnaireShape),
        questionnaireShape: questionnaireShape,
        questionDetails: questionDetails,
        revieweeAnswersObj: allUserAnsAver,
        reviewerAnswersObj: allRevAnsAver,
        reviewerCountArr: revSecCountArr,
        revieweeAnswerCount: allUserAnsCount,
        revieweeAnswerCountArr: userCountArr,
        reviewerAnswerCount: allRevAnsCount,
        radarGraphData: reviewerTypeAvBySec,
        reviewerSecCount: revSecCount,
        secLengthNoFreeText: secCountNoComents,
        userCount: revSec,
        weightedArr: weightedArr,
      });
    });
  }

  render() {
    if (!this.state.loadingState.loading) {
      let emptyData = {
        data: {},
        meta: {},
      };
      let userData = this.state.revieweeCalcs.averagesBySecDiv5;
      let chartCaptions = this.state.captions;
      const data = [
        {
          data: {},
          meta: { color: 'blue' },
        },
      ];
      for (const q in this.state.sectionDetails) {
        let counter = 0;
        for (const p in this.state.sectionDetails[q].questions) {
          if (this.state.sectionDetails[q].questions[p].answerType === 'MultiChoice') {
            counter++;
          }
        }
        //this deletes sections that are made up of only text fields
        if (counter === 0) {
          delete userData[q];
          delete chartCaptions[q];
        }
      }
      const captionsLoaded = chartCaptions;
      data[0]['data'] = userData;

      if (userData) {
        Object.keys(this.state.reviewerCalcs).map((key, index) => {
          if (Object.keys(this.state.reviewerCalcs[key].averagesBySecDiv5).length) {
            data.push({
              data: this.state.reviewerCalcs[key].averagesBySecDiv5,
              meta: {
                color: this.state.current360Relationships[key]['colour'],
              },
            });
          }
        });
      }

      const chartOptions = {
        size: 460,
        axes: true, // show axes?
        scales: 5, // show scale circles?
        captions: true, // show captions?
        captionMargin: 30,
        dots: true, // show dots?
        zoomDistance: 1.3, // where on the axes are the captions?
        axisProps: () => ({ className: 'axis', fill: 'none', stroke: '#000' }),
        scaleProps: () => ({ className: 'scale', fill: 'none', stroke: '#000' }),
        shapeProps: () => ({ className: 'shape' }),
        captionProps: () => ({
          className: 'caption',
          textAnchor: 'middle',
          fontSize: 13,
          fontFamily: 'sans-serif',
        }),
      };
      let reviewerAnswerCountArr = {};
      let reviewerAnswerCountBySec = {};
      let allUserCountArr = {};
      let allUserSecCountArr = {};

      Object.keys(this.state.sectionDetails).map((secKey) => {
        //assign the section key to the object & set the array shape
        allUserSecCountArr[secKey] = [0, 0, 0, 0, 0, 0];
        //loop through the the questions
        Object.keys(this.state.sectionDetails[secKey].questions).map((ansKey) => {
          //check if the question isn't a comments field
          if (this.state.sectionDetails[secKey].questions[ansKey].answerType === 'MultiChoice') {
            if (!reviewerAnswerCountArr[ansKey]) {
              reviewerAnswerCountArr[ansKey] = [0, 0, 0, 0, 0, 0];
              allUserCountArr[ansKey] = [0, 0, 0, 0, 0, 0];
            }
            //loop through the reviewers
            this.state.allReviewerAnswers.map((reviewer) => {
              const scoreID = Object.keys(reviewer)[0];
              const scoreObj = reviewer[scoreID];
              let reviewerAnswer = scoreObj.userAnswers[secKey][ansKey];
              if (reviewerAnswer === undefined) {
                reviewerAnswer = 0;
              }
              allUserSecCountArr[secKey][reviewerAnswer]++;
              reviewerAnswerCountArr[ansKey][reviewerAnswer]++;
              allUserCountArr[ansKey][reviewerAnswer]++;
            });
          }
        });
      });

      Object.keys(this.state.reviewerCountArr).map((revType) => {
        Object.keys(this.state.reviewerCountArr[revType]).map((secKey) => {
          if (!reviewerAnswerCountBySec[secKey]) {
            reviewerAnswerCountBySec[secKey] = [0, 0, 0, 0, 0, 0];
          }
          Object.keys(this.state.reviewerCountArr[revType][secKey]).map((ansKey) => {
            this.state.reviewerCountArr[revType][secKey][ansKey].map((answer, n) => {
              let reviewerAns = answer;
              let count = n.toString();
              let currentCount = reviewerAnswerCountBySec[secKey][count];
              reviewerAnswerCountBySec[secKey][count] = currentCount + reviewerAns;
            });
          });
        });
      });

      Object.keys(this.state.revieweeAnswerCountArr).map((secKey) => {
        Object.keys(this.state.revieweeAnswerCountArr[secKey]).map((ansKey) => {
          this.state.revieweeAnswerCountArr[secKey][ansKey].map((answer, n) => {
            allUserCountArr[ansKey][n] += answer;
            allUserSecCountArr[secKey][n] += answer;
          });
        });
      });

      let allUserPercentSecArr = {};
      Object.keys(allUserSecCountArr).map((secKey) => {
        allUserPercentSecArr[secKey] = [0, 0, 0, 0, 0, 0];
        let added = allUserSecCountArr[secKey].reduce((prev, curr) => prev + curr);
        let onePercent = added / 100;
        allUserSecCountArr[secKey].map((ans, n) => {
          allUserPercentSecArr[secKey][n] = ans / onePercent;
        });
      });

      let allUserPercentArr = {};
      Object.keys(allUserCountArr).map((ansKey) => {
        allUserPercentArr[ansKey] = [0, 0, 0, 0, 0, 0];
        let added = allUserCountArr[ansKey].reduce((prev, curr) => prev + curr);
        let onePercent = added / 100;
        allUserCountArr[ansKey].map((ans, n) => {
          allUserPercentArr[ansKey][n] = ans / onePercent;
        });
      });

      //calculate the averages of the sections
      //it builds an object, adds the sections containing all answers as an array
      //then uses the another object param to hold the calculations
      let revAnsAv = {};
      //use this for looping purposes only
      Object.keys(this.state.reviewerCalcs).map((revType) => {
        //add all section answers to the array param
        if (!revAnsAv.arrays) {
          revAnsAv.arrays = {};
        }
        Object.keys(this.state.reviewerCalcs[revType].answersBySection).map((secKey) => {
          if (!revAnsAv.arrays[secKey]) {
            revAnsAv.arrays[secKey] = [];
          }
          this.state.reviewerCalcs[revType].answersBySection[secKey].map((ans) => {
            revAnsAv.arrays[secKey].push(ans);
          });
        });
      });
      //loop over the arrays and create a new param to add them up
      //this is for the ALL REVIEWERS section field
      Object.keys(revAnsAv.arrays).map((secID) => {
        if (!revAnsAv.averages) {
          revAnsAv.averages = {};
        }
        let averageCount = 0;
        for (let index = 0; index < revAnsAv.arrays[secID].length; index++) {
          averageCount += revAnsAv.arrays[secID][index];
        }
        // revAnsAv.averages[secID] = parseFloat(averageCount / revAnsAv.arrays[secID].length);
        if (this.state.secLengthNoFreeText[secID] > 0) {
          revAnsAv.averages[secID] = parseFloat(averageCount / revAnsAv.arrays[secID].length);
        }
      });

      let secLengthNoFreeText = {};
      Object.keys(this.state.sectionDetails).map((item) => {
        let questionArr = [];
        Object.keys(this.state.sectionDetails[item].questions).map((question) => {
          if (this.state.sectionDetails[item].questions[question].answerType === 'MultiChoice') {
            questionArr.push(question);
          }
        });
        secLengthNoFreeText[item] = questionArr.length;
      });
      let colours = ['#AC8887', '#9CBFA7', '#4284D0', '#C9F299', '#F9A03F', '#777'];
      let reviewerAnswersAverage = revAnsAv.averages;
      let mergedAverageOfAnswersByRelationship = this.state.reviewerAnswersObj;
      let completedIndividualQuestionsDifferences = this.state.completedIndividualQuestionsDifferences;
      let pdfFileName = `${this.state.currentGroup.title}-group-report`.replace(' ', '-');

      return (
        <div className='page-wrapper page-wrapper--report'>
          <section className='container container--slim'>
            <Breadcrumbs
              current360={this.props.current360}
              accountData={this.props.accountData}
              current360Groups={this.props.current360Groups}
              current360Relationships={this.props.current360Relationships}
              links={[
                {
                  title: 'Dashboard',
                  to: `/dashboard/${this.props.match.params.accountId}`,
                },
                {
                  title: `${this.props.accountData.accountTitle ? this.props.accountData.accountTitle : this.props.accountData.title} ${
                    this.props.current360.type
                  } Projects`,
                  to: `/dashboard/${this.props.match.params.accountId}/${this.props.current360.type === 'Goal Tracker' ? 'goal-tracker' : '360'}-projects/`,
                },
                {
                  title: this.props.current360.title,
                  to: '../../../',
                },
                {
                  title: this.state.currentGroup.title,
                  to: `../../../group/${this.state.groupId}/`,
                },
                {
                  title: `Group Report for ${this.state.currentGroup.title}`,
                  to: null,
                },
              ]}
            />
          </section>
          <div
            className='user-report-view-only'
            style={{ height: '100vh', width: '100vw', paddingTop: 20, paddingBottom: 100, backgroundColor: 'rgb(82, 86, 89)', overflow: 'hidden' }}
          >
            <p className='tc'>
              <Link className='btn btn--back btn--white ml2 mr3' to={`../../../group/${this.state.groupId}/`}>
                Back to group overview
              </Link>
              <button className='btn btn--white ml3 mr2' onClick={this.exportPDF}>
                Export as PDF
              </button>
            </p>
            <div
              style={{
                maxWidth: '210mm',
                height: '90vh',
                backgroundColor: 'white',
                boxShadow: '0px 0px 5px #000',
                marginLeft: 'auto',
                marginRight: 'auto',
                marginBottom: 100,
                padding: '0px',
                overflowX: 'scroll',
                overflowY: 'scroll',
              }}
            >
              <PDFExport
                paperSize={'A4'}
                forcePageBreak='.page-break'
                keepTogether={'div'}
                margin={{ bottom: 50 }}
                pageTemplate={ReportPageTemplate}
                fileName={pdfFileName}
                title=''
                subject=''
                keywords=''
                ref={(r) => (this.report = r)}
              >
                <div
                  className='user-report'
                  style={{
                    height: '100%',
                    width: '100%',
                    padding: '0px',
                  }}
                >
                  <header className='user-report__header'>
                    <img crossOrigin='anonymous' src={this.props.current360.settings.logo} className='header-logo-img' />
                    <div className='w-80'>
                      <h1 className='user-report__header-title'>{this.state.current360Title}</h1>
                      <p className='user-report__header-sub-title'>
                        <strong>Group Report</strong>
                      </p>
                      <p className='user-report__header-name'>For {this.state.currentGroup.title}</p>
                    </div>
                  </header>
                  <div className='user-report__contents'>
                    <h1>Contents</h1>
                    <hr />
                    <h2 className='f3 mv4 mb3'>
                      <strong>Instructions</strong>
                    </h2>
                    <hr />
                    <h2 className='f3 mt4 mb0'>
                      <strong>Part 1</strong>
                    </h2>
                    <h4 className='f4 mb4 mt0'>Your Feedback Summary</h4>
                    <hr />
                    <h2 className='f3 mt4 mb0'>
                      <strong>Part 2</strong>
                    </h2>
                    <h4 className='f4 mb4 mt0'>Your Detailed Feedback</h4>
                    <hr />
                    <h2 className='f3 mt4 mb0'>
                      <strong>Part 3</strong>
                    </h2>
                    <h4 className='f4 mb4 mt0'>Handling feedback</h4>
                    <hr />
                  </div>
                  <div className='title-page page-break'>
                    <div className='w-60'>
                      {/*<h1 className="f3 mv0 mb3 light-blue">Part 1:</h1>*/}
                      <h2 className='f2 mv0'>Instructions</h2>
                    </div>
                  </div>
                  <div className='bg-white user-report__content'>
                    <h2 className='f2 mv0 mt4'>
                      <strong>Group 360 Degree Feedback Report</strong>
                    </h2>

                    <hr className='' />
                    <h3 className='f5 gray mt3'>Where to begin?</h3>
                    <p>
                      It’s a good idea to read the report in conjunction with your personal report. You can read the report in the order provided, or dive into
                      a specific section and extract the relevant information.
                    </p>
                    <h3 className='f5 gray'>What the report means, and what to look for.</h3>
                    <p>
                      This 360 Degree Feedback report shows the collective feedback for a group of people who have received individual 360 feedback. The report
                      is intended to help the group become more aware of how they work and interact with others, highlighting collective strengths and
                      development opportunities.
                    </p>
                    <p>
                      The report may highlight areas where the group has greater collective strengths in comparison to your own personal feedback. The converse
                      is also true – you may find that your own personal feedback in specific areas is more positive than that of the group.
                    </p>
                    <p>
                      It is critical to view the report as a group and consider what areas the group collectively wishes to focus on, to develop or change their
                      impact so that they can become collectively more effective.
                    </p>
                  </div>
                  <div className='title-page page-break'>
                    <div className='w-60'>
                      <h1 className='f3 mv0 mb3 light-blue'>Part 1:</h1>
                      <h2 className='f2 mv0'>Your Feedback Summary</h2>
                    </div>
                  </div>
                  <div className='bg-white user-report__content'>
                    <h2 className='f2 mt4 mv0'>
                      Part 1: <strong>Step 1</strong>
                    </h2>
                    <h3 className='f5 gray'>Competencies: comparison by reviewer group</h3>
                    <hr className='' />

                    <p>
                      The competency chart gives you a summary picture of how the group perceive their general strengths and development needs, compared with
                      the reviewers' perceptions.
                    </p>
                    <p>
                      Competencies where each rater group has provided similar feedback ratings indicates that the group delivers that competency consistently.
                      Critically, delivering highly rated competencies consistently, indicates a strength for the group.
                    </p>
                    <p>Conversely, delivering consistently lowly-rated competencies can point to areas where the group may need to change/improve.</p>
                    <p>
                      Areas where you might notice significant ‘gaps’ between the ratings provided by rater groups (e.g., line manager compared to direct
                      reports), suggests a mismatch between their experiences in working with the group, or different perceptions. These gaps can be explored in
                      more detail in the ’Your detailed feedback’ section of the report.
                    </p>
                    <div>
                      <h2 className='f2 mt4 mv0'>
                        Part 1: <strong>Step 1</strong>
                      </h2>
                      <h3 className='f5 gray'>Competency Chart</h3>
                      <hr className='mb4' />
                      <div className='flex w-100 page-break chartTable'>
                        <div className='w-50'>
                          <p className='mv1'>
                            <span className='w1 h1 dib br-100 bg-blue v-mid mr2'></span> <strong>Self</strong>
                          </p>
                          {this.state.reviewerCalcs
                            ? Object.keys(this.state.reviewerCalcs).map((key, index) => {
                                if (Object.keys(this.state.reviewerCalcs[key].averagesBySecDiv5).length) {
                                  return (
                                    <p key={key} className='mv1'>
                                      <span
                                        className='w1 h1 dib br-100 v-mid mr2'
                                        style={{ background: this.state.current360Relationships[key]['colour'] }}
                                      ></span>{' '}
                                      <strong>{this.state.current360Relationships[key]['name']}</strong>
                                      <br />
                                    </p>
                                  );
                                }
                              })
                            : ''}
                        </div>
                        <div className='w-50'>
                          <p className='tr'>
                            <strong>Outer ring on graph</strong> = greater frequency of behaviours
                            <br />
                            <strong>Inner ring</strong> = lower frequency of behaviours
                          </p>
                        </div>
                      </div>

                      <div className='radar'>
                        {
                          <img
                            style={{ margin: 'auto' }}
                            src={this.convertSVGToImage(<RadarChart captions={captionsLoaded} data={data} size={chartOptions.size} options={chartOptions} />)}
                          />
                        }
                      </div>
                    </div>
                  </div>

                  <div className='bg-white page-break user-report__content'>
                    <h2 className='f2 mv0 mt4'>
                      Part 1: <strong>Step 2</strong>
                    </h2>
                    <h3 className='f5 gray'>Capabilities: ranked highest to lowest</h3>
                    <hr className='' />

                    <p>
                      Here is a list of the group’s competencies, ranked highest to lowest, based on the collective feedback across all individual reports. You
                      can compare each rating against the collective ‘self-review’. This will give you an indication of the group’s general strengths and
                      developmental needs.
                    </p>

                    <div className='bb b--light-gray mv5'>
                      {/* Loop through reviewersAnswersAverage (sorting in numerical order!) */
                      reviewerAnswersAverage
                        ? Object.keys(reviewerAnswersAverage)
                            .sort(function(a, b) {
                              return reviewerAnswersAverage[b] - reviewerAnswersAverage[a];
                            })
                            .map((key, index) => {
                              // if (reviewerAnswersAverage[key] > 0 && index <= Object.keys(reviewerAnswersAverage).length - 6) {
                              if (reviewerAnswersAverage[key] > 0 && index <= 6) {
                                return (
                                  <div key={key} className='w-100 flex bt b--light-gray'>
                                    <div className='w-40'>
                                      <p style={{ marginRight: '15px' }}>{this.state.captions[key]}</p>
                                    </div>
                                    <div className='w-20'>
                                      <p style={{ color: '#F563B9' }}>
                                        <strong>Reviewers</strong>
                                      </p>
                                      <p className='blue'>
                                        <strong>Self</strong>
                                      </p>
                                    </div>
                                    <div className='w-40'>
                                      <div className='w-100'>
                                        <p className='flex'>
                                          <strong className='w-10'>{parseFloat(reviewerAnswersAverage[key]).toFixed(1)}</strong>
                                          <span className='w-100 bg-light-gray'>
                                            <span
                                              className='h-100 db'
                                              style={{ background: '#F563B9', width: `${(reviewerAnswersAverage[key] / 5) * 100}%` }}
                                            ></span>
                                          </span>
                                        </p>
                                      </div>
                                      <div className='w-100'>
                                        <p className='flex'>
                                          <strong className='w-10'>{this.state.revieweeCalcs.averagesBySection[key].toFixed(1)}</strong>
                                          <span className='w-100 bg-light-gray'>
                                            <span
                                              className='bg-blue h-100 db'
                                              style={{ width: `${(this.state.revieweeCalcs.averagesBySection[key].toFixed(1) / 5) * 100}%` }}
                                            ></span>
                                          </span>
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                );
                              }
                            })
                        : ''}
                    </div>
                  </div>

                  {Object.keys(reviewerAnswersAverage).length > 7 && (
                    <div>
                      <div className='bg-white page-break user-report__content'>
                        <h2 className='f2 mv0 mt4'>
                          Part 1: <strong>Step 2</strong>
                        </h2>
                        <h3 className='f5 gray'>Capabilities: ranked highest to lowest (continued)</h3>
                        <hr className='' />
                        {
                          <div className='bb b--light-gray mv5'>
                            {/* Loop through reviewersAnswersAverage (sorting in numerical order!) */
                            reviewerAnswersAverage
                              ? Object.keys(reviewerAnswersAverage)
                                  .sort(function(a, b) {
                                    return reviewerAnswersAverage[b] - reviewerAnswersAverage[a];
                                  })
                                  .map((key, index) => {
                                    if (reviewerAnswersAverage[key] > 0 && index > 6) {
                                      return (
                                        <div key={key} className='w-100 flex bt b--light-gray'>
                                          <div className='w-40'>
                                            <p style={{ marginRight: '15px' }}>{this.state.captions[key]}</p>
                                          </div>
                                          <div className='w-20'>
                                            <p style={{ color: '#F563B9' }}>
                                              <strong>Reviewers</strong>
                                            </p>
                                            <p className='blue'>
                                              <strong>Self</strong>
                                            </p>
                                          </div>
                                          <div className='w-40'>
                                            <div className='w-100'>
                                              <p className='flex'>
                                                <strong className='w-10'>{parseFloat(reviewerAnswersAverage[key]).toFixed(1)}</strong>
                                                <span className='w-100 bg-light-gray'>
                                                  <span
                                                    className='h-100 db'
                                                    style={{ background: '#F563B9', width: `${(reviewerAnswersAverage[key] / 5) * 100}%` }}
                                                  ></span>
                                                </span>
                                              </p>
                                            </div>
                                            <div className='w-100'>
                                              <p className='flex'>
                                                <strong className='w-10'>{this.state.revieweeCalcs.averagesBySection[key].toFixed(1)}</strong>
                                                <span className='w-100 bg-light-gray'>
                                                  <span
                                                    className='bg-blue h-100 db'
                                                    style={{ width: `${(this.state.revieweeCalcs.averagesBySection[key].toFixed(1) / 5) * 100}%` }}
                                                  ></span>
                                                </span>
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    }
                                  })
                              : ''}
                          </div>
                        }
                      </div>
                    </div>
                  )}
                  <div>
                    <div className='bg-white page-break user-report__content'>
                      <h2 className='f2 mv0 mt4'>
                        Part 1: <strong>Step 3</strong>
                      </h2>
                      <h3 className='f5 gray'>Statements: ranked highest to lowest</h3>
                      <hr className='' />
                      <p>
                        This chart shows the group’s highest ranked statements, and its lowest ranked statements, based on the feedback received. You can
                        compare each rating against the collective self-review for the same statement.
                      </p>

                      <div className='bb b--light-gray mv5'>
                        <p>
                          <strong>Highest Average Statements</strong>
                        </p>
                        {/* Loop through mergedAverageOfAnswersByRelationship (sorting in numerical order!) */
                        this.state.weightedArr
                          ? this.state.weightedArr.map((key, index) => {
                              if (mergedAverageOfAnswersByRelationship[key] > 0) {
                                return index < 5 ? (
                                  <div key={key} className='w-100 flex bt b--light-gray'>
                                    <div className='w-40 pr2'>
                                      <p style={{ marginRight: '15px' }}>{this.state.questionDetails[key].questionTitle}</p>
                                    </div>
                                    <div className='w-20'>
                                      <p style={{ color: '#F563B9' }}>
                                        <strong>Reviewers</strong>
                                      </p>
                                      <p className='blue'>
                                        <strong>Self</strong>
                                      </p>
                                    </div>
                                    <div className='w-40'>
                                      <div className='w-100'>
                                        <p className='flex'>
                                          <strong className='w-10'>{parseFloat(mergedAverageOfAnswersByRelationship[key]).toFixed(1)}</strong>
                                          <span className='w-100 bg-light-gray'>
                                            <span
                                              className='h-100 db'
                                              style={{ background: '#F563B9', width: `${(mergedAverageOfAnswersByRelationship[key] / 5) * 100}%` }}
                                            ></span>
                                          </span>
                                        </p>
                                      </div>

                                      <div className='w-100'>
                                        <p className='flex'>
                                          <strong className='w-10'>{this.state.revieweeAnswersObj[key].toFixed(1)}</strong>
                                          <span className='w-100 bg-light-gray'>
                                            <span
                                              className='bg-blue h-100 db'
                                              style={{ width: `${(this.state.revieweeAnswersObj[key].toFixed(1) / 5) * 100}%` }}
                                            ></span>
                                          </span>
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                ) : (
                                  ''
                                );
                              }
                            })
                          : ''}
                      </div>
                    </div>
                    <div className='bg-white page-break user-report__content'>
                      <h2 className='f2 mv0 mt4'>
                        Part 1: <strong>Step 3</strong>
                      </h2>
                      <h3 className='f5 gray'>Statements: ranked highest and lowest</h3>
                      <hr className='' />

                      <div className='bb b--light-gray mv5'>
                        <p>
                          <strong>Lowest Average Statements</strong>
                        </p>
                        {/* Loop through mergedAverageOfAnswersByRelationship (sorting in numerical order!) */
                        this.state.weightedArr
                          ? [...this.state.weightedArr].reverse().map((key, index) => {
                              if (mergedAverageOfAnswersByRelationship[key] > 0) {
                                return index < 5 ? (
                                  <div key={key} className='w-100 flex bt b--light-gray'>
                                    <div className='w-40 pr2'>
                                      <p style={{ marginRight: '15px' }}>{this.state.questionDetails[key].questionTitle}</p>
                                    </div>
                                    <div className='w-20'>
                                      <p style={{ color: '#F563B9' }}>
                                        <strong>Reviewers</strong>
                                      </p>
                                      <p className='blue'>
                                        <strong>Self</strong>
                                      </p>
                                    </div>
                                    <div className='w-40'>
                                      <div className='w-100'>
                                        <p className='flex'>
                                          <strong className='w-10'>{parseFloat(mergedAverageOfAnswersByRelationship[key]).toFixed(1)}</strong>
                                          <span className='w-100 bg-light-gray'>
                                            <span
                                              className='h-100 db'
                                              style={{ background: '#F563B9', width: `${(mergedAverageOfAnswersByRelationship[key] / 5) * 100}%` }}
                                            ></span>
                                          </span>
                                        </p>
                                      </div>
                                      <div className='w-100'>
                                        <p className='flex'>
                                          <strong className='w-10'>{this.state.revieweeAnswersObj[key].toFixed(1)}</strong>
                                          <span className='w-100 bg-light-gray'>
                                            <span
                                              className='bg-blue h-100 db'
                                              style={{ width: `${(this.state.revieweeAnswersObj[key].toFixed(1) / 5) * 100}%` }}
                                            ></span>
                                          </span>
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                ) : (
                                  ''
                                );
                              }
                            })
                          : ''}
                      </div>
                    </div>

                    <div className='bg-white page-break user-report__content'>
                      <h2 className='f2 mv0 mt4'>
                        Part 1: <strong>Step 4</strong>
                      </h2>
                      <h3 className='f5 gray'>Statements: greatest variations</h3>
                      <hr className='' />
                      <p>
                        Here is a list of the statements that had the most significant variation between the collective self-review and the collective
                        reviewer’s ratings. You will find both over and under-ratings listed.
                      </p>

                      <div className='bb b--light-gray mv5'>
                        <p>
                          <strong>Statements which you have over-rated the most</strong>
                        </p>
                        {/* Loop through completedIndividualQuestionsDifferences (sorting in numerical order!) */
                        completedIndividualQuestionsDifferences
                          ? Object.keys(completedIndividualQuestionsDifferences)
                              .sort(function(a, b) {
                                return completedIndividualQuestionsDifferences[b] - completedIndividualQuestionsDifferences[a];
                              })
                              .map((questionID, index) => {
                                if (completedIndividualQuestionsDifferences[questionID] > 0) {
                                  return index < 5 ? (
                                    <div key={questionID} className='w-100 flex bt b--light-gray'>
                                      <div className='w-40 pr2'>
                                        <p style={{ marginRight: '15px' }}>{this.state.questionDetails[questionID]['questionTitle']}</p>
                                      </div>
                                      <div className='w-20'>
                                        <p style={{ color: '#F563B9' }}>
                                          <strong>Reviewers</strong>
                                        </p>
                                        <p className='blue'>
                                          <strong>Self</strong>
                                        </p>
                                      </div>
                                      <div className='w-40'>
                                        <div className='w-100'>
                                          <p className='flex'>
                                            <span className='w-100 bg-light-gray'>
                                              <span
                                                className='h-100 db'
                                                style={{ background: '#F563B9', width: `${(mergedAverageOfAnswersByRelationship[questionID] / 5) * 100}%` }}
                                              ></span>
                                            </span>
                                            <strong className='w-30 tc'>{parseFloat(mergedAverageOfAnswersByRelationship[questionID]).toFixed(1)}</strong>
                                          </p>
                                        </div>
                                        <div className='w-100'>
                                          <p className='flex'>
                                            <span className='w-100 bg-light-gray'>
                                              <span
                                                className='bg-blue h-100 db'
                                                style={{ width: `${(this.state.revieweeAnswersObj[questionID].toFixed(1) / 5) * 100}%` }}
                                              ></span>
                                            </span>
                                            <strong className='w-30 tc'>
                                              {this.state.revieweeAnswersObj[questionID].toFixed(1)}{' '}
                                              <small>
                                                (+
                                                {difference(
                                                  this.state.revieweeAnswersObj[questionID].toFixed(1),
                                                  parseFloat(mergedAverageOfAnswersByRelationship[questionID]).toFixed(1)
                                                ).toFixed(1)}
                                                )
                                              </small>
                                            </strong>
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  ) : (
                                    ''
                                  );
                                } else {
                                  return index === 0 ? (
                                    <div>
                                      <h4>There are no statements where you have over-rated in comparison to the feedback you have received</h4>
                                    </div>
                                  ) : (
                                    ''
                                  );
                                }
                              })
                          : ''}
                      </div>
                    </div>

                    <div className='bg-white page-break user-report__content'>
                      <h2 className='f2 mv0 mt4'>
                        Part 1: <strong>Step 4</strong>
                      </h2>
                      <h3 className='f5 gray'>Statements: greatest variations</h3>
                      <hr className='' />

                      <div className='bb b--light-gray mv5 page-break'>
                        <p>
                          <strong>Statements which you have under-rated the most</strong>
                        </p>
                        {/* Loop through completedIndividualQuestionsDifferences (sorting in numerical order!) */
                        completedIndividualQuestionsDifferences
                          ? Object.keys(completedIndividualQuestionsDifferences)
                              .sort(function(a, b) {
                                return completedIndividualQuestionsDifferences[a] - completedIndividualQuestionsDifferences[b];
                              })
                              .map((questionID, index) => {
                                if (completedIndividualQuestionsDifferences[questionID] < 0) {
                                  return index < 5 ? (
                                    <div key={questionID} className='w-100 flex bt b--light-gray'>
                                      <div className='w-40 pr2'>
                                        <p style={{ marginRight: '15px' }}>{this.state.questionDetails[questionID]['questionTitle']}</p>
                                      </div>
                                      <div className='w-20'>
                                        <p style={{ color: '#F563B9' }}>
                                          <strong>Reviewers</strong>
                                        </p>
                                        <p className='blue'>
                                          <strong>Self</strong>
                                        </p>
                                      </div>
                                      <div className='w-40'>
                                        <div className='w-100'>
                                          <p className='flex'>
                                            <span className='w-100 bg-light-gray'>
                                              <span
                                                className='h-100 db'
                                                style={{ background: '#F563B9', width: `${(mergedAverageOfAnswersByRelationship[questionID] / 5) * 100}%` }}
                                              ></span>
                                            </span>
                                            <strong className='w-30 tc'>{parseFloat(mergedAverageOfAnswersByRelationship[questionID]).toFixed(1)}</strong>
                                          </p>
                                        </div>
                                        <div className='w-100'>
                                          <p className='flex'>
                                            <span className='w-100 bg-light-gray'>
                                              <span
                                                className='bg-blue h-100 db'
                                                style={{ width: `${(parseInt(this.state.revieweeAnswersObj[questionID]).toFixed(1) / 5) * 100}%` }}
                                              ></span>
                                            </span>
                                            <strong className='w-30 tc'>
                                              {this.state.revieweeAnswersObj[questionID].toFixed(1)}{' '}
                                              <small>
                                                (
                                                {difference(
                                                  this.state.revieweeAnswersObj[questionID].toFixed(1),
                                                  parseFloat(mergedAverageOfAnswersByRelationship[questionID]).toFixed(1)
                                                ).toFixed(1)}
                                                )
                                              </small>
                                            </strong>
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  ) : (
                                    ''
                                  );
                                } else {
                                  return index === 0 ? (
                                    <div key={questionID}>
                                      <h4>There are no statements where you have under-rated in comparison to the feedback you have received</h4>
                                    </div>
                                  ) : (
                                    ''
                                  );
                                }
                              })
                          : ''}
                      </div>
                    </div>
                  </div>

                  <DetailedFeedbackSection
                    loading={this.state.loading}
                    reportType='Group'
                    sectionDetails={this.state.sectionDetails}
                    questionDetails={this.state.questionDetails}
                    sectionsLegacyMode={this.state.sectionsLegacyMode}
                    secLengthNoFreeText={this.state.secLengthNoFreeText}
                    sectionAverageSelfCurrent={this.state.revieweeCalcs.averagesBySection}
                    sectionResponsesSelfCurrent={this.state.userCount}
                    sectionDistributionArrays={allUserPercentSecArr}
                    colours={colours}
                    current360Relationships={this.state.current360Relationships}
                    sectionAveragesByRelationshipCurrent={this.state.reviewerCalcs}
                    sectionResponsesByRelationshipCurrent={this.state.reviewerSecCount}
                    sectionCombinedReviewersAverage={reviewerAnswersAverage}
                    sectionCombinedReviewerResponses={reviewerAnswerCountBySec}
                    questionDistributionArrays={allUserPercentArr}
                    questionAveragesSelfCurrent={this.state.revieweeAnswersObj}
                    questionResponsesSelfCurrent={this.state.revieweeAnswerCountArr}
                    questionCombinedReviewersAverage={this.state.reviewerAnswersObj}
                    questionCombinedReviewersResponses={reviewerAnswerCountArr}
                    questionAveragesByRelationshipCurrent={this.state.reviewerCalcs}
                    questionResponsesByRelationshipCurrent={this.state.reviewerCountArr}
                    dfSectionRelationshipKeys={this.state.dfSectionRelationshipKeys}
                    relationshipKeys={this.state.relationshipKeys}
                    pdfRequested={this.state.pdfRequested}
                    updatePDFExportPreRenderStatus={this.updatePDFExportPreRenderStatus}
                  />

                  <div className='title-page page-break'>
                    <div className='w-60'>
                      <h1 className='f3 mv0 mb3 light-blue'>Part 3:</h1>
                      <h2 className='f2 mv0'>Handling Feedback</h2>
                    </div>
                  </div>

                  <div className='bg-white user-report__content'>
                    <h2 className='f2 mv0 mt4'>
                      Part 3: <strong>Handling Feedback</strong>
                    </h2>
                    <h3 className='f5 gray'>Responding to feedback</h3>
                    <hr className='' />

                    <h3 className='f5 mt4'>
                      <strong>Take some time</strong>
                    </h3>
                    <p>
                      Receiving feedback can be challenging. Even if the input is praise, you may have difficulty in knowing how to respond to it, or you may
                      feel embarrassed by it. When the feedback is constructive, emotional and psychological reactions can be complicated and can get in the way
                      of seeing where the real value lies in the information. Take some time to reflect and absorb the feedback before you consider your
                      response.
                    </p>

                    <h3 className='f5 mt4'>
                      <strong>Receiving praise</strong>
                    </h3>
                    <p>Receiving praise is not as easy as it may seem.</p>
                    <p>
                      When people are given a compliment, whether professionally or personally, there are several ways they may react that reduces the impact of
                      the praise:
                    </p>
                    <p>
                      &bull; Devaluation: "It’s just me doing my job."
                      <br />
                      &bull; Distrust: "People are just saying that; they are just being nice."
                      <br />
                      &bull; Deflection: "My team does all the hard work for me."
                    </p>
                    <p>
                      All of these are common responses, but it is crucial to understand why you may use them. If these are normal reactions for you, consider
                      why you feel the need to use them.
                    </p>
                    <p>
                      Sometimes, self-critical people can find it awkward to accept praise, as it does not fit with their view of themselves. In some cases,
                      they set their personal bar too high for themselves, or anyone else, to reach realistically. If this sounds like you, make it a
                      developmental target to try to persuade yourself that perfection is not always necessary, to perform well in your job and your life.
                    </p>
                    <p>
                      Accepting a genuine compliment and positive feedback gracefully can be both modest and assertive. The best way to take positive feedback
                      is to thank the provider, genuinely and happily. They gave you the feedback for a reason, and they will most likely be happy to see it has
                      the desired effect.
                    </p>
                  </div>
                  <div className='page-break bg-white user-report__content'>
                    <h3 className='f5 mt4'>
                      <strong>Be open-minded</strong>
                    </h3>
                    <p>
                      Open your mind up to the potential for change. Remember that constructive feedback is not about you as a person, but about job
                      behaviours/actions. Because you have not come up to a necessary standard in some way, does not make you a bad person or a failure—it
                      certainly does not mean the person offering the feedback is suggesting you are. It merely means you have the scope to learn and improve.
                      The person providing feedback should be focused on helping you to do this, so try to see the input from that perspective.
                    </p>

                    <h3 className='f5 mt4'>
                      <strong>Contextualise, analyse and confirm</strong>
                    </h3>
                    <p>
                      Remember that you have the right to analyse and then confirm the feedback you received for accuracy. Consider the context in which the
                      feedback was provided, to help support your thinking.
                    </p>
                    <p>
                      If you are uncertain about some of the feedback you have received, or feel unsure about how to respond to the feedback positively; talk to
                      a trusted colleague, manager, coach or colleague within HR/Learning. Book some time to sit down and discuss your concerns and ask them for
                      help interpreting the feedback. If needed, they can help you to build some effective developmental solutions in response to the feedback.
                    </p>

                    <h3 className='f5 mt4'>
                      <strong>Move forward</strong>
                    </h3>
                    <p>
                      With any feedback, the critical response is to consider what you can do to improve in future. Can the people giving you feedback help?
                      Don’t be afraid to ask them for suggestions and assistance in making the necessary changes. All being well, they will be happy to do this.
                      Work with them to develop a plan of action.
                    </p>

                    <h3 className='f5 mt4'>
                      <strong>Build your developmental plan</strong>
                    </h3>
                    <p>
                      All your feedback is intended to help you learn, develop and grow. Take the time to consider how you can use the feedback to help shape
                      your future development. What is your report telling you about your opportunities to develop? Reflect on how you can put this feedback
                      into action, by fleshing out your development plan with practical and positive actions and activities.
                    </p>
                  </div>
                  <div className='page-break bg-white user-report__content'>
                    <h2 className='f2 mv0'>
                      Part 3: <strong>Handling Feedback</strong>
                    </h2>
                    <h3 className='f5 gray'>Handling critical feedback</h3>
                    <hr className='' />

                    <p>
                      Receiving critical feedback about yourself from other people can sometimes result in strong emotional reactions, particularly where your
                      sense of who you are, or your self-concept, has been challenged.
                    </p>
                    <p>This is quite normal and may lead to some of the following emotions and behaviours being exhibited:</p>

                    <h3 className='f5 mt4'>
                      <strong>Shock</strong>
                    </h3>
                    <p>
                      When receiving feedback that people do not recognise as being part of who they are, they may consciously or unconsciously demonstrate
                      disbelief and a sense of shock. Typically, this is often followed by denial and a refusal to accept the facts or feedback. This is a
                      defence mechanism and is perfectly natural, but it can take an individual some time to overcome it.
                    </p>

                    <h3 className='f5 mt4'>
                      <strong>Annoyance</strong>
                    </h3>
                    <p>
                      Annoyance manifests itself in various ways. People may be upset with themselves, but more often, it is directed at external parties,
                      including the people who provided the feedback or the person delivering the message.
                    </p>

                    <h3 className='f5 mt4'>
                      <strong>Refusal</strong>
                    </h3>
                    <p>
                      In the refusal stage people may begin to be less annoyed, but still resist the message by challenging the validity of the process, or by
                      starting to make excuses.
                    </p>
                    <p>
                      Following this stage, people may appear to have accepted the message, by declaring that 'I understand what you are saying', but without
                      really meaning it. This may, in turn, lead to objections being raised further down the line, with the key stakeholders in the feedback
                      process.
                    </p>

                    <h3 className='f5 mt4'>
                      <strong>Acceptance</strong>
                    </h3>
                    <p>
                      Having had time to evaluate the information objectively and to come to terms with what people have said, acceptance usually occurs. This
                      stage represents an excellent opportunity for an individual to get some coaching or training to enhance their strengths or to address some
                      areas of development.
                    </p>
                  </div>

                  <header className='page-break user-report__header'>
                    <img crossOrigin='anonymous' src={this.props.current360.settings.logo} className='header-logo-img' />
                    <div className='w-80'>
                      <h1 className='user-report__header-title'>Good Luck</h1>
                      <p className='user-report__header-sub-title'>
                        <strong>Own the change you want to see! </strong>
                      </p>
                    </div>
                  </header>
                </div>
              </PDFExport>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div>
          {this.state.error ? (
            <div className='error-404 bg-blue fixed left-0 top-0'>
              <h1 className='white mt4'>System Error - Error loading report</h1>
            </div>
          ) : (
            <div className='w-100 flex-wrap flex items-center justify-center'>
              <p className='w-100 tc center'>Loaded Reviewees: {this.state.loadingState.reviewee}</p>
              <img src={loadingGif} />
            </div>
          )}
        </div>
      );
    }
  }
}

export default withAuthorisation(authCondition)(Group360Report);
